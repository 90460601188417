@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.mainpopup {
  margin: auto;
  height: 76vh;
  width: 72vw;
  // padding: 30px;
  background: var(--popup--background);
  border-radius: 5px;
  text-align: center;
  overflow-y: scroll;
  max-height: 96vh;
}

.iconholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.over {
  font-family: "nunito";
  font-size: 16px;
  font-weight: 400;
  // padding-bottom: 10px;
  // text-align: center;
}

.header {
  font-family: "nunito";
  font-size: 26px;
  font-weight: 400;
  // padding-bottom: 20px;
  text-align: center;
}

.title {
  width: 100%;
  border: 1px solid #6a6a6a;
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px;
}

.condition {
  font-family: "nunito";
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 10px;
}

.textarea {
  width: 100%;
  border: 1px solid #6a6a6a;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px;
  resize: none;
}

.fileinput {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 40px 0px;
  border: 1px dashed #6a6a6a;
}

.browse:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-top: 20px;
  margin: 2rem;
}

.submit {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}
.fdsubmit {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
  margin-top: 10px;
}

.prev {
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.next {
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.prev1 {
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  // padding: 0px 20px;
  //margin-top: 10px;

  margin: 10px;

}

.next1 {
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  // padding: 0px 20px;
  margin-top: 10px;
  margin: 10px;
}

.prev:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.submit:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.next:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.overholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
  border: 1px solid black;
  border-radius: 5px;
}

.overinput {
  // width: 8%;
  height: 30px;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
}

.questionsection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  height: auto;
  overflow-y: auto;
}

.questioncount {
  padding: 20px 0px;
}

.question {
  font-family: "nunito";
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}

.questiontext {
  text-align: center;
  font-size: 12px;
}

.areaholder {
  width: 100%;
  padding: 20px 10px;
}

.feedback {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 55px;
}
.feedback svg {
  margin-right: 8px;
}
.tabclass .ui.tabular.menu .item {
  pointer-events: none !important;
}

.details {
  //  width: 100%;
  display: flex;
  //  align-items: center;
  //justify-content: space-between;
  gap: 20px;
  //  padding: 20px;
  //border: 1px solid black;
  //border-radius: 5px;
}
