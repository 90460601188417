@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


.maincontainer{
  padding: 20px;
  margin: 0px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.management {
    padding: 0 0rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.h1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0px;
    }

  .header{
    font-family: "nunito";
    font-size: 25px;
    font-weight: 400;
    padding-bottom: 20px;
  }

    .management_info {
      max-height: auto;
      margin-bottom: 4rem;
      .flex_box {
        display: flex;
      
        gap: 1rem;
        max-height: auto;
        height: 330px;
        &__container {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          gap: 2rem;
          flex: 0 0 70%;
          overflow: hidden;
        }
        .management__image {
          flex: 0 0 29%;
          background-image: url("https://source.unsplash.com/user/erondu/1600x900");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px;
          height: 290px;
        }
      }
    }
  
  .text_align {
    text-align: left;
    padding: 0 3rem;
  }

  .savebutton{
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
  }

  .savebutton:hover{
    cursor: pointer;
    transform: scale(1.1);
  }

  .management__image {
    flex: 0 0 29%;
    background-image: url("https://source.unsplash.com/user/erondu/1600x900");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 200px;
  }
  .flex_box {
    display: flex;
    gap: 1rem;
    max-height: auto;
  
    height: 330px;
  }
  .imagebox{
    width: 100%;
    object-fit: cover;
  }
  .eimagebox{
    height: 290px;
    min-width: 345px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  .management_info {
    max-height: auto;
    margin-bottom: 4rem;
  }
  .time_line {
    display: flex;
  }
  .inputsize {
    font-size: 18px !important;
    font-weight: bold;
    letter-spacing: 0.0211rem;
    width: 100%!important;
    @media screen and (min-width: 900px) and (max-width: 1300px) {
      font-size: var(--para-size-medium);
    }
  }
  .addnugget{
    margin: 5px 0% 15px 0%;
    padding: 16px;
    width: 100%;
    background-color: #fff;
    border: 1px dashed #767676;
  }
  .nuggets{
    border: 1px solid #ddd;
  }
  .objects{
    padding: 10px;
  }
  .objecttype {
    width: 175px;
    padding: 10px;
    border: 1px solid lightgrey;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 15px 40px 15px !important;
}
.objbtns{
  margin: 40px 80px 10px 80px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.objbtnsmain{
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.modalpopup{
  // min-width: 600px ;
  // width: 70% ;
}
.modalfooter{
  border: none !important;
  justify-content:  center !important;
}
.verror1{
  position: fixed; font-size: 12px; margin: -15px 0 0px 102px;  color: red;
}
.pvalid{
  position: fixed; font-size: 12px; margin: -15px 0 0px 66px;  color: red;
}
.insidediv{
  
    margin: 20px 60px 19px 65px;
    overflow: hidden;
    padding: 40px;
    width: 632px;
    border: 2px dashed #999999;
   
}
.centeritem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pfile{
  margin: 5px 0 0 0 !important; color: #796b6b; text-align: center;
}
.ctitle {
  font-weight: bold;
  position: absolute !important;
  width: 50% !important;
  color: #fff !important;
  transform: translate(52%, 439%);
}
.imgbl{
  
    position: absolute;
    height: 330px;
    width: 100%;
    opacity: .4;
   
}
.displayflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 1%);
  padding: 1rem 1.5rem;
}

.popadd{
  font-family: "nunito";
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: black !important;
}

.buttonholder{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 20px 0px;
}

.save{
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.cancel{
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.save:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.cancel:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.inputholder{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputbox{
  width: 100%;
    border: 1px solid #6a6a6a;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px;
}