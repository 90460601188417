.buttonholder1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 15px;
    padding: 2rem;
    gap: 2rem;
}

.addbutton {
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}

.addbutton:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.addnugget {
    margin: 5px 0% 15px 0%;
    padding: 16px;
    width: 100%;
    background-color: #fff;
    border: 1px dashed #767676;
}

.nuggets {
    border: 1px solid #ddd;
}

.objects {
    padding: 10px;
}

.objecttype {
    width: 175px;
    padding: 10px;
    border: 1px solid lightgrey;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 15px 40px 15px !important;
}

.inputsize {
    font-size: 18px !important;
    font-weight: bold;
    letter-spacing: 0.0211rem;
    width: 100% !important;

    @media screen and (min-width: 900px) and (max-width: 1300px) {
        font-size: var(--para-size-medium);
    }
}

.buttonholder {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 20px 0px;
}

.save {
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}

.cancel {
    background: transparent;
    border: 1px solid var(--button-background);
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-background);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}



.save:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.cancel:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.inputholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inputbox {
    width: 100%;
    border: 1px solid #6a6a6a;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px;
}

.insidediv {
    margin: 10px;
    text-align: center;
    overflow: hidden;
    padding: 40px;
    width: 632px;
    border: 2px dashed #999999;
    margin-left: 8rem;

}