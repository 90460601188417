.textLabelField {
  color: black;
  border: none;
  padding: 7px;
  font-size: 1.5rem;
  box-shadow: none;
  transition: all 0.2s;
  text-align: center;
  width: 22rem;
  border-radius: 2rem;
  overflow-wrap: break-word;
  font-weight: 800;
}

.replyIcon {
  width: 26px;
  height: 26px;
}

.clock_size__O {
    width: 2rem;
    height: 2rem;
    margin: auto;
  }
  
  .cls1_s {
    fill: none;
    stroke: orange;
    stroke-miterlimit: 10;
    stroke-width: 5.55px;
    stroke-linecap: round;
  }
  .cls2_s {
    fill: none;
    stroke: orange;
    stroke-miterlimit: 10;
    stroke-width: 5.55px;
    stroke-linecap: round;
  }

  .Project_Side {
    justify-content: space-between;
    margin: "50px";
  }

  .button{
    background: var(--button-background);
    border: none;
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 10px;
}
  