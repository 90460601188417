@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


.maincontainer {
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    margin: 0px;
  }

.manage {
    display: flex;
    justify-content: space-between;
    align-content: center;
    // padding-top: 30px;
}

.heading{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}


.search{
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    height: 25px;
    padding: 0px 10px;
    margin-right: 30px;
}

.inputfield:focus {
    outline: none;
}

.image {
    width: 200px;
    height: 100px;
    border-radius: 10px;
}

.table{
    padding-top: 30px;
}

.titleclick{
    text-decoration: none;
}

.titleclick:hover{
    cursor: pointer;
    transform: scale(1.01);
    text-decoration: underline;
}

.batchbutton{
    color: var(--sub-heading-color);
    text-decoration: none ;
    font-size: 14px !important;
    font-weight: 400px;
    border: none;
    background: transparent;
}

.batchtitle{
    color: var(--sub-heading-color);
    text-decoration: none ;
    font-size: 14px !important;
    font-weight: bold;
    border: none;
    background: transparent ;
}

.batchtitle:hover{
    text-decoration: underline;
}

.btn_color {
    background: var(--button-background);
    padding: 10px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: white;
    font-weight: 300px;
    border-radius: 5px;
    border: none;
    margin: 0px;
  }