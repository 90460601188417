@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.containermain{
    width: 60%;
    height: fit-content;
    background: white;
    border-radius: 2px;
    justify-content: center;
    margin: auto;
    padding: 20px;
}

.iconholder{
    display: flex;
    justify-content: flex-end;
    align-items: middle;
    height: 20px;
}

.icon{
    height: 100%;
}

.icon:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.heading {
    font-family: "nunito";
    font-size: 20px !important;
    padding: 20px 0px !important;
    font-weight: 400 !important;
    padding-left: 5px;
}

.form{
    padding: 20px;
}

.field{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin: auto;
    gap: 20px;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 75%;
    gap: 30px;
    margin-bottom: 20px;
}

.buttonstable{
    font-family: "nunito";
    width: fit-content;
    height: 30px;
    color: #13458e;
    border: 1px solid #13458e;
    border-radius: 5px;
    background: transparent;
    font-size: 16px;
    padding: 5px 10px;
}

.buttonactive{
    font-family: "nunito";
    width: fit-content;
    height: 30px;
    color: white;
    border: 1px solid #13458e;
    border-radius: 5px;
    background: #13458e;
    font-size: 16px;
    font-weight: 400;
    padding: 5px 10px;
}

.dateholder{
    width: 75%;
    display: flex;
    justify-content: flex-start;
}

.submitbuttonholder{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.submitbutton{
    background: #e35f14;
    border: none;
    border-radius: 5px;
    height: 25px;
    width: fit-content;
    color: white;
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    margin: auto;
    padding: 0px 20px;
}


