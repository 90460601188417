:root {
  --color-primary: #eb2f64;
  --color-primary-light: #ff3366;
  --color-primary-dark: #ba265d;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #3372b5;
  --color-grey-dark-2: #01498e;
  --color-grey-dark-3: #999;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);
}

$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

.sidebar {
  background-image: linear-gradient(
    to top,
    var(--color-grey-dark-2) 30%,
    var(--color-grey-dark-1)
  );

  flex: 0 0 18%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
}

.logo-container {
  height: 60px;
  width: auto;
  margin-bottom: 3rem;

  img {
    height: 100%;
    width: auto;
  }
}
.side-nav {
  font-size: 1.75rem;
  list-style: none;
  margin-top: 3rem;
  padding: 0;

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: var(--color-grey-light-2);
    transform: scaleY(0);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
      background-color 0.1s;
  }
  &__item--active {
    span {
      color: var(--header-color) !important;
      font-weight: bold;
    }
    .side-nav__icon {
      fill: var(--header-color) !important;
    }
    background-color: whitesmoke;
  }

  

  //   &__item:hover::before,
  //   &__item--active::before {
  //     transform: scaleY(1);
  //     width: 100%;
  //   }

  &__item--active::before {
    transform: scaleY(1);
    width: 100%;
  }

  &__item:active::before {
    background-color: var(--color-grey-light-2);
  }

  &__link:link,
  &__link:visited {
    color: var(--color-grey-light-1);
    text-decoration: none;
    // text-transform: uppercase;
    display: block;
    padding: 1.5rem 3rem;
    position: relative;
    z-index: 10;

    display: flex;
    align-items: center;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    fill: white !important;
  }
}

.legal {
  color: #fff !important;
  //margin-bottom: 2rem;
  font-size: 1.2rem;

  img {
    height: 2.2rem;
  }
}

.fill {
  fill: #fff !important;
}
