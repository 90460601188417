@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maincontainer {
  background: whitesmoke;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.header {
  font-family: "nunito";
  font-size: 26px;
  font-weight: 400;
  padding-left: 5px;
}

.subheader {
  font-family: "nunito";
  font-size: 26px;
  font-weight: 400;
  padding-left: 5px;
  margin-top: 500px;
}

.createbutton {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.createbutton:hover {
  cursor: pointer;
  transform: scale(1.06);
}

.cardholder {
  padding-top: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.cards {
  width: 300px;
  padding: 00px;
  margin: 0px;
}

.image {
  width: 300px;
  height: 150px;
}

.coursename {
  font-family: "nunito";
  color: #6a6a6a !important;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 0px;
}

.coursecontent {
  font-family: "nunito";
  color: #2a4696 !important;
  font-size: 20px;
  font-weight: 500;
}

.coursecontent:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.disablebutton {
  display: flex;
  justify-content: center;
  background: transparent;
  width: fit-content;
  font-family: "nunito";
  color: var(--button-background);
  padding: 0px 10px;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  font-size: 16px;
  margin: 15px auto;
}

.disablebutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.enablebutton {
  display: flex;
  justify-content: center;
  background: transparent;
  width: fit-content;
  font-family: "nunito";
  color: white;
  padding: 0px 10px;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  font-size: 16px;
  margin: 15px auto;
  background-color: var(--button-background);
}

.enablebutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}
