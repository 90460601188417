.mainpopup{
    height: 96vh;
    width: 96vw;
    background: white;
    margin: auto;
    padding: 20px;
}

.iconholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.icon:hover{
    cursor: pointer;
}

.mediaholder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    height: 84vh;
    overflow-y: auto;
}

.image{
    height: 400px;
    width: 80%;
}