@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.popupcontainer{
    margin: auto;
    height: fit-content;
    width: 400px;
    padding: 30px;
    background: var(--popup--background);
    border-radius: 5px;
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 20px;
    text-align: center;
}

.radioholder{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding: 10px 0px;
}

.radiolable{
    font-family: "nunito";
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
    padding-bottom: 20px;
    text-align: center;
}

.input{
    height: 10px;
    width: 10px;
}

.inputlabel{
    font-family: "nunito";
    font-size: 16px;
    font-weight: 400;
}

.inputbox{
    width: 100%;
    border: 1px solid #6a6a6a;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px 0px;
}

.submit{
    background: var(--button-background);
    border: none;
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    padding: 0px 20px;
}

.submit:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.cancel{
    background: transparent;
    border: 1px solid var(--button-background);
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: var(--button-background);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    padding: 0px 20px;
}

.cancel:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.caution{
    font-family: "nunito";
    font-size: 12px;
    font-weight: bold;
    padding-top: 10px;
    color: red;
}
