@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maindiv {
    background: var(--app-background);
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
    padding-bottom: 20px;
}

.aboveheader{
    background: white;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.addbutton{
    background: var(--button-background);
    border: none;
    border-radius: 5%;
    height: 25px;
    width: 70px;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
}

.addbutton:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.search{
    background: var(--button-text);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    height: 30px;
    padding: 0px 10px;
    margin-right: 30px;
}

.inputfield:focus {
    outline: none;
}

.iconholder {
    padding: 2px 4px;
    border: 1px solid #e35f14;
    background: transparent;
    border-radius: 5px;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
}
