form {
    font-weight: 600;
}
li {
    margin: 1.5rem 0rem;
    padding-left: 1.5rem;
}
li > div {
    display: flex;
    justify-content: space-between;
}
b {
    font-weight: 900;
}
th,td{
    font-size: 13px;
}
.profile{
    margin: 2rem 0rem;
    border: 1px solid;
    width: 15rem;
    height: 9rem;
}
.headerContainer{
    display: flex;
    justify-content: space-evenly;
}
.spaceBetween{
    display: flex;
    justify-content: space-between;
}
.subHeading {
    background-color: rgb(224 225 226);
    padding: 0.5rem 1rem;
}
.checkboxContent {
    display: flex; 
    justify-content: space-between;
    width: 50%;
}
.inputContainer {
    width: 80%!important;
}
.inputContainer2 {
    width: 35%!important;
}
.inputContainer3 {
    width: 100%!important;
}
.inputContainer4 {
    width: 70%!important;
}
.inputContainer3 > textarea {
    border: 1.5px solid black!important;
}
.inputField {
    border: none!important; 
    border-radius: 0!important;
    padding: 4px!important;
    background-color: #fff;
    width: 100%;
}
.tableInputField {
    border: none!important;
    border-radius: 0!important;
    padding: 0px!important;
    padding: 7px!important;
    width: 100%;
}
.buttonContainer{
    align-items: center;
    text-align: center;
    margin: 2rem;
}
.buttonContainer > button{
    /* background-color: #da251c!important;*/
    color: black!important;
    width: 13rem!important;
}
#containers{
    max-width: 100% !important;
    line-height: 1.8rem !important;
    font-size: 15px !important;
}
.mainform  > ol  > li{
    padding: 6px 0 !important;
}
.mainform > ol {
    list-style-type: none;
}
.rlabel > ol  > li {
    padding: 0px 0 !important;
}
.mainform  input:checked + label{
    border: none !important;
  
}
.mainform  input{
    pointer-events: none;
}
.dochead{
    padding: 10px 0;
    font-family: "nunito";
    font-size: 16px;
    font-weight: 400;
    width: 50%;
}
@media only screen and (max-width: 800px) {
    ol{
        padding-inline-start: 18px;
    }
    li{
        padding-left: 1rem;
    }
    li > div {
        display: block;
    }
    .profile{
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
    .checkboxContent {
        width: 100%!important;
    }
    .headerContainer{
        display: block!important;
    }
    .spaceBetween > label{
        display: block!important;
    }
    .spaceBetween{
        display: block!important;
    }
    .inputContainer{
        width: 100%!important;
    }
    .inputContainer2{
        width: 100%!important;
        margin-bottom: 1rem;
    }
    .inputContainer3{
        width: 100%!important;
    }
    .inputContainer4{
        width: 100%!important;
    }
}