@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.containermain {
  background: var(--app-background);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  margin: 0px;
}

.heading {
  font-family: "nunito";
  font-size: 26px;
  font-weight: 400;
  padding-left: 5px;
}

.topsection {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0px;
}

.leftsection {
  width: 50%;
}

.rightsection {
  width: 50%;
}

.individual {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
}

.leftside {
  width: 50%;
  text-align: right;
}

.rightside {
  width: 50%;
  text-align: left;
}

.typeleft {
  font-size: 16px;
  font-family: "nunito";
  font-weight: 500;
  color: #6a6a6a;
}

.typeright {
  font-size: 16px;
  font-family: "nunito";
  font-weight: 500;
  color: black;
  background: #fff5ef;
  border: none;
  border-radius: 5px;
  width: fit-content;
  padding: 2px 10px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 16px;
  font-family: "nunito";
  font-weight: 600;
  padding: 10px 0px 0px 180px;
  color: var(--sub-heading-color);
  text-decoration: underline;
  text-align: center;
  border: none;
}

.link:hover {
  color: var(--sub-heading-color);
  cursor: pointer;
  transform: scale(1.03);
}

.links:hover {
  text-decoration: none;
}

.bottomsection {
  margin-top: 10px;
}

.firstrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}
.lastrow {
  display: flex;
  margin-left: 27%;
  margin-right: 27%;
  // width:fit-content;
  // align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.cardindividual {
  width: 250px;
  height: 160px;
  padding: 20px;
}

.cardindividualdis:hover {
  cursor: not-allowed;
}

.icontittle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.iconholder {
  width: 35px;
  height: 35px;
}

.cardheading {
  font-size: 20px;
  font-family: "nunito";
  color: var(--sub-heading-color);
  font-weight: 400;
}

.cardheading:hover {
  cursor: pointer;
  text-decoration: none;
  transform: scale(1.05);
}

.listblock {
  padding-top: 10px;
  font-size: 14px;
  padding-left: 20px;
}

.listitems {
  padding: 5px 0px;
  list-style-type: disc;
  color: #6a6a6a;
  font-family: "nunito";
  font-weight: 600;
}

.manageicons {
  width: 100%;
  height: 100%;
  fill: var(--header-color);
}

.manageiconsdisabled {
  width: 100%;
  height: 100%;
  fill: grey;
}

.linksdis {
  color: grey;
}

.cardheadingdis {
  font-size: 20px;
  font-family: "nunito";
  color: grey !important;
  font-weight: 400;
}

.cardindividualdisabled {
  width: 250px;
  height: 160px;
  padding: 20px;
  cursor: not-allowed;
}

.cls1dis {
  fill: none;
  stroke: grey;
}

.cl2dis {
  fill: none;
  stroke: grey;
}

.cls1 {
  fill: none;
  stroke: var(--header-color);
}

.cls2 {
  fill: none;
  stroke: var(--header-color);
}

.cls3 {
  fill: none;
  stroke: var(--header-color);
}

.cls4 {
  font-size: 5px;
  fill: none;
  stroke: var(--header-color);
}
.info_cls_1 {
  fill: #e35f14;
}
.info_cls_2 {
  fill: #fff;
}
