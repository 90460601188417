.management {
  padding: 0 0rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.h1 {
  margin-bottom: 2rem;
}

.management_info {
  max-height: auto;
  margin-bottom: 4rem;

  .flex_box {
    display: flex;
    gap: 1rem;
    max-height: auto;
    height: auto;

    &__container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 2rem;
      flex: 0 0 66%;
      overflow: hidden;
    }

    .management__image {
      flex: 0 0 29%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      height: 200px;
    }
  }
}

.text_align {
  text-align: left;
  padding: 0 3rem;
}

.management__image {
  flex: 0 0 29%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  height: 200px;
}

.flex_box {
  display: flex;
  gap: 1rem;
  max-height: auto;
  height: auto;
}

.management_info {
  max-height: auto;
  margin-bottom: 4rem;
}

.time_line {
  display: flex;
}

.inputsize {
  font-size: 18px !important;
  font-weight: bold;
  letter-spacing: 0.0211rem;
  width: 100% !important;

  @media screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: var(--para-size-medium);
  }
}

.disablebutton {
  display: flex;
  justify-content: center;
  background: transparent;
  width: fit-content;
  font-family: "nunito";
  color: var(--button-background);
  padding: 0px 10px;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  font-size: 16px;
  margin: 15px auto;
}

.disablebutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.enablebutton {
  display: flex;
  justify-content: center;
  background: transparent;
  width: fit-content;
  font-family: "nunito";
  color: white;
  padding: 0px 10px;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  font-size: 16px;
  margin: 15px auto;
  background-color: var(--button-background);
}

.enablebutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}
