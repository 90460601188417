@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.containermain{
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    margin: 0px;
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}

.topsection{
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftsection{
    font-family: "nunito";
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
}

.leftsectionleft{
    font-family: "nunito";
    font-size: 20px;
    font-weight: 400;
}

.number{
    font-family: "nunito";
    font-size: 25px;
    font-weight: 400;
}

.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.iconholder{
    height: 20px;
    width: 20px;
}

.bottomsection{
    padding-top: 10px;
}
.topbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rightsection{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.notification{
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 25px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 10px;
}

.notification:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.downloadicon {
    padding: 2px 4px;
    border: 1px solid var(--button-background);
    background: transparent;
    border-radius: 5px;
}

.search{
    background: var(--app-background);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    height: 30px;
    padding: 0px 10px;
}

.inputfield:focus {
    outline: none;
}

.datagrid{
    padding-top: 20px;
}

.percentholder{
    width: 22%;
    text-align: center;
}