.maincontainer{
    background: var(--app-background);
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
    padding-bottom: 20px;
}

.button{
    background: var(--button-background);
    border: none;
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 10px;
}

.button:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.holder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}