.maincontainer{
    background: var(--app-background);
    width: 100%;
    height: 100%;
    padding: 30px;
    overflow-y: auto;
   
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
    padding-bottom: 20px;
}

.holder{
    padding: 30px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 20px 0px;
    
   page-break-after: always;
  
}

.blockheader{
    color: var(--sub-heading-color);
    font-weight: 600;
    font-family: "nunito";
    font-size: 20px;
}

.detailsholder{
    width: 100%;
    padding-top: 10px;
}

.head{
    padding: 10px 0px;
    font-family: "nunito";
    font-size: 16px;
    font-weight: 400;
    width: 50%;
}

.innerholder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.keyspan{
    width: 50%;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px 0px;
}

.submit{
    background: var(--button-background);
    border: none;
    border-radius: 5px ;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    padding: 0px 20px;
}

.submit:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.cancel{
    background: transparent;
    border: 1px solid var(--button-background);
    border-radius: 5px ;
    height: 30px;
    width: fit-content;
    color: var(--button-background);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    padding: 0px 20px;
}

.cancel:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.spinnerdiv{
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }