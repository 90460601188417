.classInput_assign_assess {
  border: none;
  min-width: 500px;
  box-shadow: 0px 0px 5px 0px lightgrey;
  background: white;
  height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  padding-left: 10px;
  font-weight: 200px;
  outline: none;
}

.classInput_assign_assess-match {
  border: none;
  width: 250px;
  flex-grow: 1;
  box-shadow: 0px 0px 5px 0px lightgrey;
  background: white;
  height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  padding-left: 10px;
  font-weight: 200px;
  outline: none;
}

.classLabel_assign_assess {
  font-size: 16px;
  font-weight: 500px;
  font-family: nunito;
}

.classInput_Instructor {
  border: none;
  min-width: 400px;
  box-shadow: 0px 0px 5px 0px lightgrey;
  background: white;
  height: 30px;
  font-size: 15px;
  margin-left: 2rem;
  padding-left: 10px;
  font-weight: 200px;
  outline: none;
  flex-grow: 1;
}

.firstViewValidation {
  color: red;
  font-size: 1.35rem;
}

.classInput_MatchTheFollowing {
  border: none;
  min-width: 200px;
  box-shadow: 0px 0px 5px 0px lightgrey;
  background: white;
  height: 30px;
  font-size: 15px;
  padding-left: 10px;
  font-weight: 200px;
  outline: none;
  flex-grow: 1;
}

.classInput_Marks {
  border: none;
  width: 100px;
  box-shadow: 0px 0px 5px 0px lightgrey;
  background: white;
  height: 30px;
  font-size: 15px;
  padding-left: 10px;
  font-weight: 200px;
  outline: none;
  flex-grow: 1;
}
