@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


.maincontainer{
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    margin: 0px;
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}

.template{
    padding: 20px;
    margin-top: 20px;
    background: white;
}

.subjectsection{
    display:flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.label{
    font-size: 16px;
    font-family: "nunito";
    color: "#808080";
    padding-top: 10px;
}

.icon:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.emailbody{
    display:flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 50px;
}

.label{
    width: 100px;
}

.buttonholder{
    padding: 20px 0px 0px 0px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.cancel{
    background: transparent;
    border: 1px solid var(--button-background);
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: var(--button-background);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    margin-right: 30px;
    padding: 0px 20px;
}

.save{
    background: var(--button-background);
    border: none;
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    margin-right: 30px;
    padding: 0px 20px;
}

.cancel:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.save:hover{
    cursor: pointer;
    transform: scale(1.1);
}