.maindiv {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containermain {
  width: 50%;
  height: fit-content;
  background: var(--popup--background);
  border-radius: 2px;
  justify-content: center;
  margin: auto;
  padding: 20px;
}

.iconcontainer {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

.namebutton {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 50px;
}

.username {
  font-size: 20px;
  font-family: "nunito";
  color: #000 !important;
  font-weight: 400;
}

.userbutton {
  font-family: "nunito";
  border: 1px solid #04549c;
  border-radius: 5px;
}

.maindivision {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  padding: 20px;
  max-height: 80vh;
  overflow: auto;
}

.imageholder {
  width: 30%;
  vertical-align: middle;
}

.profile {
  width: 200px;
  height: 200px;
  border: 2px solid grey;
  border-radius: 50%;
  margin-top: 80px;
  margin-left: 30px;
}

.form {
  width: 90%;
  text-align: center;
  margin: 10px auto;
}

.field {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin: auto;
  gap: 20px;
}

.submit {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 70px;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: 500;
  font-size: 18px;
}

.submit:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}
