.topbar{
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftside{
    display: flex;
    gap: 20px;
}

.rightside{
    display: flex;
    gap: 20px;
}

.select{
    font-size: 18px;
    font-weight: 600;
    font-family: "nunito";
}

.addbutton{
    background: var(--button-background);
    border: none;
    border-radius: 5%;
    height: 25px;
    padding: 0px 10px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
}

.search{
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    height: 30px;
    padding: 0px 10px;
}

.inputfield:focus {
    outline: none;
}

.iconholder {
    padding: 2px 4px;
    border: 1px solid #e35f14;
    background: transparent;
    border-radius: 5px;
}