@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.maindiv{
    width:100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.maincontainer {
    width: 50%;
    height: fit-content;
    border-radius: 2px;
    background: var(--popup--background);
    justify-content: center;
    margin: auto;
    padding: 20px;
}

.iconcontainer{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
}

.create{
    font-size: 20px;
    font-family: "nunito";
    color: #000 !important;
    font-weight: 400;
}

.form{
    padding: 20px;
    text-align: center;
    margin: 10px auto;
}

.field{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin: auto;
    gap: 20px;
}   

.submit{
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 25px;
    width: 70px;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
}

.submit:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.closebutton:hover{
    cursor: pointer;
    transform: scale(1.1);
}


