@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


.containermain{
    background: var(--app-background);
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
}

.heading{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
    padding-bottom: 20px;
}

.headingQuestions{
    font-family: "nunito";
    font-size: 18px;
    font-weight: 400;
    padding-left: 5px;
    margin-right: 200px;
    padding-bottom: 20px;
    float: left;
    margin-top: 10px;
}

.headingindividualQuestions{
    font-family: "nunito";
    font-size: 18px;
    font-weight: 400;
    padding-left: 5px;
    padding-bottom: 20px;
    margin-top: 10px;
}

.headingFeedback{
    font-family: "nunito";
    font-size: 16px;
    font-weight: 900;
    padding-left: 5px;
    padding-bottom: 20px;
    float: left;
}

.headingIndividualFeedback{
    font-family: "nunito";
    font-size: 16px;
    font-weight: 900;
    padding-left: 5px;
    padding-bottom: 20px;
    color: var(--sub-heading-color);
    float: right;
    cursor: pointer;
}

.togglebuttonholder{
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.togglebuttonstable{
    border: none;
    color: var(--sub-heading-color);
    font-family: "nunito";
    font-size: 20px;
    font-weight: 400;
    background: transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px;
    width: fit-content;
    height: 40px;
}

.togglebuttonactive{
    border: none;
    color: var(--button-background);
    font-family: "nunito";
    font-size: 20px;
    font-weight: 400;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px;
    width: fit-content;
    height: 40px;
}

.cardholder{
    padding: 30px 20px;;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    background: white;
}

.cards {
    width: 300px;
    padding: 0px;
    margin: 0px;
    min-height: 300px;

}

.image{
    width: 300px;
    height: 150px;
}

.coursename{
    font-family: "nunito";
    color: #6a6a6a !important;
    font-size: 16px;
    font-weight: 300;
    padding: 20px 0px;
}

.coursecontent{
    font-family: "nunito";
    color: var(--sub-heading-color) !important;
    font-size: 20px;
    font-weight: 500;
}

.coursecontent:hover{
    cursor: pointer;
    transform: scale(1.01);
}
