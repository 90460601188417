@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maincontainer{
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    margin: 0px;
}

.heading{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}

.gridholder{
    padding-top: 30px;
    height: 90%;
}

.gridcontainer{
    padding: 20px;
    height: 100%;
}