.buttonholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 15px;
}

.addbutton{
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}

.addbutton:hover{
    cursor: pointer;
    transform: scale(1.1);
}