.cancel{
    background: var(--button-background);
    border: none;
    border-radius: 5px ;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    padding: 0px 20px;
    margin-right: 10px;
}

.notification{
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 25px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 10px;
}

.head {
    font-size: 14px;
    font-weight: 400;
  }