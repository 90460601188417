@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.mainpopup{
    margin: auto;
    height: fit-content;
    width: 500px;
    padding: 50px;
    background: var(--popup--background);
    border-radius: 5px;
    text-align: center;
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 20px;
}

.form{
    width: 100%;
    padding: 10px 0px;
}

.fieldholder{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.labelholder{
    width: 40%;
}

.inputholder{
    width: 60%;
}

.label{
    font-family: "nunito";
    font-size: 15px;
    font-weight: 400;
}

.date{
    width: 100%;
    border: 1px solid #6a6a6a;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px;
}

.select{
    width: 100%;
    border: 1px solid #6a6a6a;
    height: 30px;
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px;
}

.area{
    width: 100%;
    border: 1px solid #6a6a6a;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px;
    margin-top: 5px;
    resize: none;
    font-family: "nunito";
}

.cautionholder{
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.caution{
    font-family: "nunito";
    font-size: 12px;
    font-weight: bold;
    padding-top: 10px;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px 0px;
}


.submit{
    background: var(--button-background);
    border: none;
    border-radius: 5px ;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}

.submit:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.cancel{
    background: transparent;
    border: 1px solid var(--button-background);
    border-radius: 5px ;
    height: 30px;
    width: fit-content;
    color: var(--button-background);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}

.cancel:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.label{
    font-family: "nunito";
    font-size: 15px;
    font-weight: 400;
    padding: 20px;
}

.checkbox{
    height: 10px;
    width: 10px;
    border: 1px solid black;
}

.multiselect{
    width: 100%;
    border: 1px solid #6a6a6a;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px;
}